<template>
    <div :class="`wrapper  wrapper--default m-3 p-2`">
        <h3 class="text-center">Budget Analysis</h3>
        <hr>
        <Loading v-if="loading" />
        <div v-else  class="budgetanalysis--flexbox">
            
            <div v-for="(item, key) in budget_analysis" :key="key" class="budgetanalysis--tablecontainer">
                <h6 class="text-center"><strong>{{ key }}</strong></h6>
                <newBudgetTable :table_data="item['budget']" :table_sum="item['budget_sum']" table_style="200" />
            </div>
        </div>
    </div>
</template>

<script>
import request from '../functions/request'
import newBudgetTable from '../components/newBudgetTable.vue';
import Loading from '../components/Loading.vue';

export default {
    props: ["bu"],
    components: { newBudgetTable, Loading },
    data: () => ({
        budget_analysis: null,
        loading: null,

    }),
    created() {
        this.loading = true;
        this.getData(this.bu)
    },
    watch: {
        bu(newbu){
            this.loading = true;
            this.getData(newbu)
        }
    },
    methods: {
        getData(bu) {
            request(`budget-analysis/${bu}`, "GET").then(({ budget_analysis }) => {
                this.budget_analysis = budget_analysis;
                this.loading = false
            })
        }
    }
};

</script>
